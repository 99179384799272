import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth/auth.service';
import { LoggingService } from '../services/logging.service';
import { AuthStorageService } from '../services/auth/auth-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private name: string = 'AuthGuard';

    constructor(private router: Router, private authService: AuthService, private authStorageService: AuthStorageService, private loggingService: LoggingService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let operation: string = 'canActivate';
        this.loggingService.trace(this.name, operation);
        return new Promise((resolve) => {
            const existingToken = this.authStorageService.getAuthorizationCredentials();
            if (existingToken) {
                this.authService.checkToken(existingToken.jwtBearer).subscribe(
                    (x) => {
                        if (!x) {
                            this.reject();
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    },
                    (err) => {
                        console.log('err', err);
                        this.reject();
                        resolve(false);
                    }
                );
            } else {
                this.reject();
                resolve(false);
            }
        });
    }

    reject() {
        this.authStorageService.clearAuthorizationCredentials();
        this.router.navigate(['/auth/login']);
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }
}
