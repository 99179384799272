import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../constants/global-constants';
import { TokenModel } from 'app/core/interfaces/token.interface';

/**
 * Authorization storage service for app authentication
 */
@Injectable({ providedIn: 'root' })
export class AuthStorageService {
    constructor() {}

    /**
     * Store authorization credentials in local storage
     * @param loginResponse - The response from login
     */
    setAuthorizationCredentials(loginResponse): void {
        let value = JSON.stringify(loginResponse);
        let key = GlobalConstants.authStorageKey;
        localStorage.setItem(key, value);
    }

    /**
     * Retrieve authorization credentials from local storage
     */
    getAuthorizationCredentials(): TokenModel {
        let key = GlobalConstants.authStorageKey;
        let value = localStorage.getItem(key);
        let response = JSON.parse(value);
        return response;
    }

    /**
     * clear authorization credentials from local storage
     */
    clearAuthorizationCredentials(): void {
        let key = GlobalConstants.authStorageKey;
        localStorage.removeItem(key);
    }
}
