import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

// Needed for notification service
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { LayoutModule } from '../layouts/layout.module';

import { GraphQLModule } from './graphql.module';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, LayoutModule, MatSnackBarModule, GraphQLModule],
    exports: [FlexLayoutModule, CommonModule, LayoutModule, MatSnackBarModule, GraphQLModule],
    declarations: [],
    providers: [],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
        }
    }
}
