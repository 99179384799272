import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { MenuLayoutComponent } from './menu-layout/menu-layout.component';
import { HeaderComponent } from './header/header.component';
import { AppLoaderComponent } from './app-loader/app-loader.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

const mat = [
    MatToolbarModule,
    MatExpansionModule,
    MatGridListModule,
    MatMenuModule,
    MatTreeModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatButtonModule,
];

const layout = [FlexLayoutModule, ScrollingModule, CdkTableModule, CdkTreeModule];

const components = [AuthLayoutComponent, MenuLayoutComponent, HeaderComponent, AppLoaderComponent];

@NgModule({
    imports: [CommonModule, RouterModule, ...layout, ...mat],
    exports: [...components],
    declarations: [...components],
})
export class LayoutModule {}
