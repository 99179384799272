import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { DefaultOptions } from 'apollo-client/ApolloClient';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import apolloLogger from 'apollo-link-logger';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { GlobalConstants } from '../constants/global-constants';
import { AuthStorageService } from '../services/auth/auth-storage.service';
import { AuthService } from '../services/auth/auth.service';
import { LoggingService } from '../services/logging.service';
import { ToastService } from '../services/toast.service';
import { UserService } from '../services/user/user.service';

@NgModule({
    exports: [ApolloModule, HttpLinkModule],
    providers: [],
})
export class GraphQLModule {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    name: string = 'GraphQLModule';

    constructor(
        private apollo: Apollo,
        private httpLink: HttpLink,
        private authService: AuthService,
        private userService: UserService,
        private authStorageService: AuthStorageService,
        private loggingSerivce: LoggingService,
        private notificationService: ToastService,
        private router: Router
    ) {
        const setAuthorization = async (_, { clientAwareness, headers }) => {
            let operation: string = 'constructor.setAuthorization';
            // this.loggingSerivce.trace(this.name, operation)

            const credentials = this.authStorageService.getAuthorizationCredentials();

            if (credentials && _.operationName !== 'ExchangeRefreshToken' && _.operationName !== 'CheckToken') {
                if (credentials) {
                    let jwtBearer: any = credentials.jwtBearer;
                    let isBearerValid = await this.authService.checkToken(jwtBearer).toPromise();

                    if (!isBearerValid) {
                        if (!this.isRefreshing) {
                            this.isRefreshing = true;
                            this.refreshTokenSubject.next(null);

                            await this.authService
                                .exchangeRefreshToken(credentials.jwtRefresh)
                                .pipe(
                                    switchMap((token: any) => {
                                        this.isRefreshing = false;
                                        this.refreshTokenSubject.next(token.jwtBearer);
                                        return token.jwtBearer;
                                    })
                                )
                                .toPromise()
                                .then((_) => {
                                    this.userService.getLoggedInUser().toPromise();
                                });
                        } else {
                            jwtBearer = await this.refreshTokenSubject
                                .pipe(
                                    filter((token) => token != null),
                                    take(1),
                                    switchMap((jwt) => {
                                        return jwt;
                                    })
                                )
                                .toPromise();
                        }
                        return {
                            headers: {
                                ...headers,
                                Authorization: `Bearer ${this.refreshTokenSubject.value}`,
                            },
                        };
                    }

                    return {
                        headers: {
                            ...headers,
                            Authorization: `Bearer ${jwtBearer}`,
                        },
                    };
                }
            } else {
                return {
                    headers,
                };
            }
        };

        const errorHandler = (args): void => {
            let operationName: string = 'errorHandler';
            // this.loggingSerivce.trace(this.name, operationName)

            // this.loggingSerivce.debug('errorHandler args', this.name, operationName, args)

            let graphQLErrors = args.graphQLErrors;
            let networkError: Error = args.networkError;

            if (graphQLErrors) {
                for (let err of graphQLErrors) {
                    this.loggingSerivce.error(`${err.internalCode}: ${err.message}`, this.name, operationName, err);
                    let errorMessage: string = '';

                    errorMessage = errorMessage + '\n' + err.details.join('\n');

                    if (!errorMessage.includes('password change')) {
                        this.notificationService.warn(errorMessage);
                    }

                    switch (err.internalCode) {
                        case 4000:
                            // this.loggingSerivce.info(err.internalCode.toString(), this.name, operationName)
                            break;
                        case 4001:
                            // this.loggingSerivce.info(err.internalCode.toString(), this.name, operationName)
                            // this.router.navigate(['/auth/login'], {});
                            break;
                        case 4003:
                            // this.loggingSerivce.info(err.internalCode.toString(), this.name, operationName)
                            break;
                        case 4004:
                            // this.loggingSerivce.info(err.internalCode.toString(), this.name, operationName)
                            break;
                        case 5000:
                            // this.loggingSerivce.info(err.internalCode.toString(), this.name, operationName)
                            break;
                        case 5001:
                            // this.loggingSerivce.info(err.internalCode.toString(), this.name, operationName)
                            break;
                        default:
                            this.loggingSerivce.warn('Unknown error code', this.name, operationName);
                            break;
                    }
                }
            }
            if (networkError) {
                this.loggingSerivce.error('networkError', this.name, operationName, networkError);
                this.notificationService.warn(networkError.message);
            }
        };

        // Apollo link logger
        const logger = ApolloLink.from([apolloLogger]);

        // Apollo http
        const http = this.httpLink.create({
            uri: GlobalConstants.graphQlUrl,
        });

        // Error handler
        const error = onError(errorHandler);

        // Basic headers
        const basic = setContext((operation, context) => ({
            headers: {
                Accept: 'charset=utf-8',
                'Content-Type': 'application/json',
                'App-Version': '1.0.0',
            },
        }));

        // set auth headers
        const auth = setContext(setAuthorization);

        // Create link
        const link = ApolloLink.from([basic, auth, logger, error, http]);

        // Cache
        const cache = new InMemoryCache();

        const defaultOptions: DefaultOptions = {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        };

        this.apollo.create({
            link,
            cache,
            defaultOptions,
        });
    }
}
