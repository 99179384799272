import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    AuthLayout: boolean = false;
    MenuLayout: boolean = false;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.AuthLayout = this.activatedRoute.firstChild.snapshot.data.AuthLayout !== false;

                this.MenuLayout = this.activatedRoute.firstChild.snapshot.data.MenuLayout !== false;

                // this.title = this.activatedRoute.firstChild.firstChild.snapshot.data.title;
                // this.module = this.activatedRoute.firstChild.snapshot.data.title;

                // document.title = this.title;
            }
        });
    }
}
