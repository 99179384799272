export const Menu = [
    {
        title: 'Active Tow Location',
        icon: './assets/menu/location.png',
        path: '/active-tow-location',
    },
    {
        title: 'Municipalities',
        icon: './assets/menu/municipality.svg',
        path: '/municipalities',
    },
    {
        title: 'Tow Companies',
        icon: './assets/menu/tow-companies.svg',
        path: '/tow-companies',
    },
    {
        title: 'Compliance',
        icon: './assets/menu/checklist.png',
        path: '/rotation-packet',
    },
    {
        title: 'Users',
        icon: './assets/menu/users.svg',
        path: '/users',
    },
    {
        title: 'Tow Log',
        icon: './assets/menu/log.png',
        path: '/tow-log',
    },
    {
        title: 'Reporting',
        icon: './assets/menu/report.png',
        path: '/reporting',
    },
    {
        title: 'View Rotation',
        icon: './assets/menu/rotation.png',
        path: '/rotation',
    },
];
