import gql from 'graphql-tag';

export const LOGGED_IN_USER = gql`
    query LoggedInUser {
        LoggedInUser {
            id
            username
            createdAt
            updatedAt
            profile {
                ...profileFields
            }
            Roles {
                pagingMeta {
                    count
                }
                data {
                    id
                    name
                }
            }
            Tokens {
                pagingMeta {
                    count
                }
                data {
                    id
                    tokenType
                    expiresAt
                }
            }
        }
    }

    fragment profileFields on Profile {
        firstName
        lastName
        email
        countryCode
        phone
    }
`;

export const USER_LIST = gql`
    query UserList($userInput: UserPagingInput!, $CompanyInput: CompanyPagingInput!) {
        LoggedInUser {
            Users(input: $userInput) {
                pagingMeta {
                    ...pagingMeta
                }
                data {
                    ...userFields
                    Companies(input: $CompanyInput) {
                        ...companyFields
                    }
                }
            }
        }
    }

    fragment pagingMeta on PagingMetaResult {
        count
        startIndex
        endIndex
        hasNextPage
    }

    fragment entityFields on Entity {
        id
        createdAt
        updatedAt
    }

    fragment profileFields on Profile {
        firstName
        lastName
        email
        countryCode
        phone
    }

    fragment userFields on User {
        ...entityFields
        username
        active
        profile {
            ...profileFields
        }
    }

    fragment towCompanyFields on TowCompany {
        __typename
        id
        name
        active
    }

    fragment muniCompanyFields on Municipality {
        __typename
        id
        name
        active
    }

    fragment companyFields on CompanyPagingResult {
        ... on MunicipalityPagingResult {
            pagingMeta {
                ...pagingMeta
            }
            data {
                ...muniCompanyFields
            }
        }
        ... on TowCompanyPagingResult {
            pagingMeta {
                ...pagingMeta
            }
            data {
                ...towCompanyFields
            }
        }
    }
`;

export const USER = gql`
    query User($userInput: UserPagingInput!, $CompanyInput: CompanyPagingInput!) {
        LoggedInUser {
            Users(input: $userInput) {
                pagingMeta {
                    ...pagingMeta
                }
                data {
                    ...shortUserFields
                    driver {
                        ...shortDriverFields
                    }
                    Roles(input: { paging: { first: 500, skip: 0, orderBy: "name.asc" } }) {
                        data {
                            ...shortRoleFields
                        }
                    }
                    Companies(input: $CompanyInput) {
                        ...companyFields
                    }
                }
            }
        }
    }

    fragment pagingMeta on PagingMetaResult {
        count
        startIndex
        endIndex
        hasNextPage
    }

    fragment entityFields on Entity {
        id
        createdAt
        updatedAt
    }

    fragment profileFields on Profile {
        firstName
        lastName
        email
        countryCode
        phone
    }

    fragment shortUserFields on User {
        ...entityFields
        username
        active
        profile {
            ...profileFields
        }
    }

    fragment shortDriverFields on Driver {
        id
        municipalities {
            id
        }
    }

    fragment tcCompanyFields on TowCompany {
        __typename
        id
        name
        active
    }

    fragment muniCompanyFields on Municipality {
        __typename
        id
        name
        active
    }

    fragment companyFields on CompanyPagingResult {
        ... on MunicipalityPagingResult {
            pagingMeta {
                ...pagingMeta
            }
            data {
                ...muniCompanyFields
            }
        }
        ... on TowCompanyPagingResult {
            pagingMeta {
                ...pagingMeta
            }
            data {
                ...tcCompanyFields
            }
        }
    }

    fragment shortRoleFields on Role {
        ...entityFields
        name
    }
`;

export const USER_CREATE = gql`
    mutation UserCreate($input: UserCreate!) {
        UserCreate(input: $input) {
            ...shortUserFields
            Roles(input: { paging: { first: 500, skip: 0, orderBy: "name.asc" } }) {
                data {
                    ...shortRoleFields
                }
            }
        }
    }

    fragment entityFields on Entity {
        id
        createdAt
        updatedAt
    }

    fragment profileFields on Profile {
        firstName
        lastName
        email
        countryCode
        phone
    }

    fragment shortUserFields on User {
        ...entityFields
        username
        active
        profile {
            ...profileFields
        }
    }

    fragment shortRoleFields on Role {
        ...entityFields
        name
    }
`;

export const USER_UPDATE = gql`
    mutation UserUpdate($input: UserUpdate!) {
        UserUpdate(input: $input) {
            ...shortUserFields
            Roles(input: { paging: { first: 500, skip: 0, orderBy: "name.asc" } }) {
                data {
                    ...shortRoleFields
                }
            }
        }
    }

    fragment entityFields on Entity {
        id
        createdAt
        updatedAt
    }

    fragment profileFields on Profile {
        firstName
        lastName
        email
        countryCode
        phone
    }

    fragment shortUserFields on User {
        ...entityFields
        username
        active
        profile {
            ...profileFields
        }
    }

    fragment shortRoleFields on Role {
        ...entityFields
        name
    }
`;

export const USER_PASSWORD_UPDATE = gql`
    mutation PasswordUpdate($input: PasswordUpdateInput!) {
        PasswordUpdate(input: $input) {
            ...shortUserFields
            Roles(input: { paging: { first: 500, skip: 0, orderBy: "name.asc" } }) {
                data {
                    ...shortRoleFields
                }
            }
        }
    }

    fragment entityFields on Entity {
        id
        createdAt
        updatedAt
    }

    fragment profileFields on Profile {
        firstName
        lastName
        email
        countryCode
        phone
    }

    fragment shortUserFields on User {
        ...entityFields
        username
        active
        profile {
            ...profileFields
        }
    }

    fragment shortRoleFields on Role {
        ...entityFields
        name
    }
`;

export const USER_DELETE = gql`
    mutation UserDisable($input: UserDisableInput!) {
        UserDisable(input: $input)
    }
`;
