import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Menu } from 'app/core/constants/menu';
import { AuthStorageService } from 'app/core/services/auth/auth-storage.service';
import { UserService } from 'app/core/services/user/user.service';
import { LoggedInUserModel } from 'app/core/interfaces/user.interface';

@Component({
    selector: 'app-menu-layout',
    templateUrl: './menu-layout.component.html',
    styleUrls: ['./menu-layout.component.scss'],
})
export class MenuLayoutComponent implements OnInit, OnDestroy {
    opened = true;
    over = 'side';
    expandHeight = '42px';
    collapseHeight = '42px';
    displayMode = 'flat';
    watcher: Subscription;

    loggedInUser: LoggedInUserModel;

    nav = [...Menu];

    isSuperAdmin = false;

    constructor(private media: MediaObserver, private router: Router, private authStorageService: AuthStorageService, private userService: UserService) {
        this.watcher = this.media.asObservable().subscribe((change) => {
            const size = change[0];
            if (size.mqAlias === 'sm' || size.mqAlias === 'xs' || size.mqAlias === 'md') {
                this.opened = false;
                this.over = 'over';
            } else {
                this.opened = true;
                this.over = 'side';
            }
        });
    }

    ngOnInit() {
        this.userService.getLoggedInUser().subscribe((user) => {
            this.loggedInUser = user;
            if (this.loggedInUser.Roles.data.find((x) => x.name == 'SuperAdmin')) {
                this.isSuperAdmin = true;
            }

            if (!this.isSuperAdmin) {
                this.nav = this.nav.filter((x) => !['Compliance', 'Reporting'].includes(x.title));
            }
        });
    }

    ngOnDestroy(): void {
        this.watcher.unsubscribe();
    }

    onLogOut(): void {
        this.authStorageService.clearAuthorizationCredentials();
        this.router.navigate(['auth', 'login']);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }
}
