<mat-sidenav-container autosize>
    <mat-sidenav color="primary" #sidenav [mode]="over" [(opened)]="opened">
        <div fxLayout="column" fxLayoutAlign="start" class="menu-item-wrapper">
            <div class="pv-5-remx pl-5-rem" fxLayout="row" fxLayoutAlign="space-between center">
                <a routerLink="/">
                    <img src="./assets/green-logo.png" alt="App logo" width="90px" />
                </a>

                <a mat-button class="toggle-menu" (click)="sidenav.toggle()">
                    <mat-icon>menu</mat-icon>
                </a>
            </div>

            <mat-list>
                <mat-list-item *ngFor="let link of nav">
                    <a mat-button class="text-decoration-none" routerLink="{{ link.path }}" routerLinkActive="active-link">
                        <span class="circle mr-1-rem">
                            <img [src]="link.icon" />
                        </span>
                        <span class="font-300 letter-spacing-1">{{ link.title }}</span>
                    </a>
                </mat-list-item>
            </mat-list>

            <div class="text-align-center add-tow-wrapper">
                <button mat-raised-button color="accent" routerLink="/tow-request">New Tow</button>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar color="light" *ngIf="loggedInUser">
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                    <a mat-icon-button (click)="sidenav.toggle()" *ngIf="!opened" class="toggle-menu">
                        <mat-icon>menu</mat-icon>
                    </a>
                </div>
                <div class="fs-1-rem font-400">
                    <a mat-button [matMenuTriggerFor]="menu" class="user-name">
                        <div>
                            {{ loggedInUser.profile.firstName }}
                            {{ loggedInUser.profile.lastName }}

                            <mat-icon>arrow_drop_down</mat-icon>
                        </div>
                    </a>

                    <mat-menu #menu="matMenu" overlapTrigger="false">
                        <button mat-menu-item routerLink="/users/form/{{ loggedInUser.id }}">
                            <mat-icon>person</mat-icon>
                            <span>Profile</span>
                        </button>
                        <button mat-menu-item (click)="onLogOut()">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>Log Out</span>
                        </button>
                    </mat-menu>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
        <app-loader></app-loader>
        <div class="p-1-rem">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
