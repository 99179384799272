import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, BehaviorSubject } from 'rxjs';
import { concatMap, map, tap, take } from 'rxjs/operators';
import { LOGIN, EXCHANGE_REFRESH_TOKEN, CHECK_TOKEN, REQUEST_PASSWORD_CHANGE, CHANGE_PASSWORD } from './auth.gql';
import { APP } from '../gql/home.gql';

import { AuthStorageService } from './auth-storage.service';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private loggedInSubject: BehaviorSubject<boolean>;
    public loggedIn: Observable<boolean>;

    constructor(private apollo: Apollo, private authStorageService: AuthStorageService, private userService: UserService) {
        this.loggedInSubject = new BehaviorSubject<boolean>(this.authStorageService.getAuthorizationCredentials() ? true : false);
        this.loggedIn = this.loggedInSubject.asObservable();
    }

    public get loggedInValue(): boolean {
        return this.loggedInSubject.value;
    }

    /**
     *
     */
    login(login): Observable<any> {
        return this.apollo
            .mutate({
                mutation: LOGIN,
                variables: {
                    input: {
                        username: login.username,
                        password: login.password,
                    },
                },
            })
            .pipe(
                tap((_) => console.log('Logged in user')),
                concatMap((result) => {
                    let response = result.data['LogIn'];
                    this.authStorageService.setAuthorizationCredentials(response);
                    this.loggedInSubject.next(true);

                    return this.userService.getLoggedInUser().pipe(take(1));
                })
            );
    }

    /**
     *
     */
    checkToken(jwtBearer: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: CHECK_TOKEN,
                variables: {
                    input: {
                        jwtBearer,
                    },
                },
            })
            .pipe(
                tap((_) => console.log('checked token')),
                map((result) => {
                    let response: boolean = result.data['CheckToken'];
                    return response;
                })
            );
    }

    /**
     *
     */
    exchangeRefreshToken(jwtRefresh: string): Observable<any> {
        return this.apollo
            .mutate({
                mutation: EXCHANGE_REFRESH_TOKEN,
                variables: {
                    input: {
                        jwtRefresh,
                    },
                },
            })
            .pipe(
                tap((_) => console.log('exchanged token')),
                map((result) => {
                    let response = result.data['ExchangeRefreshToken'];
                    let x = this.authStorageService.getAuthorizationCredentials();
                    x.jwtBearer = response.jwtBearer;
                    this.authStorageService.setAuthorizationCredentials(x);
                    return response;
                })
            );
    }

    app() {
        return this.apollo
            .watchQuery({
                query: APP,
            })
            .valueChanges.subscribe((result) => {
                console.log(result);
            });
    }

    requestPasswordChange(payload: any) {
        return this.apollo
            .mutate({
                mutation: REQUEST_PASSWORD_CHANGE,
                variables: {
                    input: payload,
                },
            })
            .pipe(
                tap((_) => console.log('Request password change')),
                map((result) => {
                    let response: boolean = result.data['RequestPasswordChange'];
                    return response;
                })
            );
    }

    changePassword(payload: any) {
        return this.apollo
            .mutate({
                mutation: CHANGE_PASSWORD,
                variables: {
                    input: payload,
                },
            })
            .pipe(
                tap((_) => console.log('Change password')),
                map((result) => {
                    let response: boolean = result.data['ChangePassword'];
                    return response;
                })
            );
    }
}
