import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/modules/core.module';
import { HammerConfig } from './core/config/hammer.config';

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, HammerModule, AppRoutingModule, BrowserAnimationsModule, CoreModule, HttpClientModule],
    providers: [
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
