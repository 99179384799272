import { environment } from 'environments/environment';

export class GlobalConstants {
    public static production: boolean = environment.production;
    public static debug: boolean = environment.debug;

    public static siteTitle: string = 'TowPro';

    public static apiUrl: string = `${environment.apiUrl}${environment.apiVersion}/`;
    public static graphQlUrl: string = `${GlobalConstants.apiUrl}graphql/`;

    public static authStorageKey: string = `${GlobalConstants.siteTitle}_auth`;

    public static requestTimeout: number = 30000;
    public static requestTimeoutMessage: string = 'You request has timed out';

    public static dateFormat: string = 'MMMM Do YYYY, h:mm:ss a';
}
