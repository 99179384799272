import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from './../services/user/user.service';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    constructor(private auth: AuthService, private userService: UserService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkUserRole(next);
    }

    private checkUserRole(route: ActivatedRouteSnapshot) {
        const role = route.data.role;
        if (!this.auth.loggedInValue) {
            return false;
        }

        return this.userService.getLoggedInUser().pipe(
            take(1),
            map((user) => {
                return !!user.Roles.data.find((r) => r.name === role);
            })
        );
    }
}
