import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Roles } from './core/enums/roles.enum';

import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
        data: { AuthLayout: true, MenuLayout: false },
    },
    {
        path: 'municipalities',
        loadChildren: () => import('./modules/municipalities/municipalities.module').then((m) => m.MunicipalitiesModule),
        data: { MenuLayout: true, AuthLayout: false, roles: ['SuperAdmin'] },
        canActivate: [AuthGuard],
    },
    {
        path: 'tow-companies',
        loadChildren: () => import('./modules/tow-companies/tow-companies.module').then((m) => m.TowCompaniesModule),
        data: { MenuLayout: true, AuthLayout: false },
        canActivate: [AuthGuard],
    },
    {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
        data: { MenuLayout: true, AuthLayout: false },
        canActivate: [AuthGuard],
    },
    {
        path: 'tow-log',
        loadChildren: () => import('./modules/tow-log/tow-log.module').then((m) => m.TowLogModule),
        data: { MenuLayout: true, AuthLayout: false },
        canActivate: [AuthGuard],
    },
    {
        path: 'profile',
        loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
        data: { MenuLayout: true, AuthLayout: false },
        canActivate: [AuthGuard],
    },
    {
        path: 'tow-request',
        loadChildren: () => import('./modules/tow-request/tow-request.module').then((m) => m.TowRequestModule),
        data: { MenuLayout: true, AuthLayout: false },
        canActivate: [AuthGuard],
    },
    {
        path: 'rotation-packet',
        loadChildren: () => import('./modules/rotation-packet/rotation-packet.module').then((m) => m.RotationPacketModule),
        data: { MenuLayout: true, AuthLayout: false, role: Roles.ADMIN },
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'reporting',
        loadChildren: () => import('./modules/reporting/reporting.module').then((m) => m.ReportingModule),
        data: { MenuLayout: true, AuthLayout: false, role: Roles.ADMIN },
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'rotation',
        loadChildren: () => import('./modules/view-rotation/view-rotation.module').then((m) => m.ViewRotationModule),
        data: { MenuLayout: true, AuthLayout: false },
        canActivate: [AuthGuard],
    },
    {
        path: 'active-tow-location',
        loadChildren: () => import('./modules/active-tow-location/active-tow-location.module').then((m) => m.ActiveTowLocationModule),
        data: { MenuLayout: true, AuthLayout: false },
        canActivate: [AuthGuard],
    },
    {
        path: 'not-found',
        loadChildren: () => import('./modules/not-found/not-found.module').then((m) => m.NotFoundModule),
        data: { AuthLayout: true, MenuLayout: false },
    },
    {
        path: '**',
        redirectTo: 'not-found',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
