import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LOGGED_IN_USER, USER_LIST, USER, USER_CREATE, USER_UPDATE, USER_PASSWORD_UPDATE, USER_DELETE } from './user.gql';

import { LoggingService } from '../logging.service';
import { TableInfoModel } from '../../interfaces/table-info.interface';
import { TableResponseModel, DataResponseModel } from '../../interfaces/http-response.interface';
import { UserTableItemModel, LoggedInUserModel, UserModel } from '../../interfaces/user.interface';
import { ApolloQueryResult } from 'apollo-client';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private name: string = 'UserService';
    private loggedInUserSubject: BehaviorSubject<LoggedInUserModel>;
    public loggedInUser: Observable<LoggedInUserModel>;

    constructor(private apollo: Apollo, private loggingService: LoggingService) {
        this.loggedInUserSubject = new BehaviorSubject<any>(null);
        this.loggedInUser = this.loggedInUserSubject.asObservable();
    }

    getLoggedInUser(): Observable<LoggedInUserModel> {
        return this.apollo
            .watchQuery({
                query: LOGGED_IN_USER,
            })
            .valueChanges.pipe(
                tap((_) => console.log('Fetched Logged in user')),
                map((result: ApolloQueryResult<DataResponseModel<LoggedInUserModel>>) => {
                    let user = result.data.LoggedInUser;
                    this.loggedInUserSubject.next(user);
                    return user;
                })
            );
    }

    checkRoles(roles: []) {
        let operation: string = 'checkRoles';
        return this.getLoggedInUser().pipe(
            map((user) => {
                let userRoleIds = user.Roles.data.map((x) => x.name);

                if (roles && roles.some((role) => userRoleIds.includes(role))) {
                    this.loggingService.debug('Authorized', this.name, operation);
                    // Authorized so return true
                    return true;
                }
                this.loggingService.debug('not Authorized', this.name, operation);
                // role not authorised so return false
                return false;
            })
        );
    }

    getTableData(pageModel: TableInfoModel): Observable<TableResponseModel<UserTableItemModel>> {
        return this.apollo
            .watchQuery({
                query: USER_LIST,
                fetchPolicy: 'no-cache',
                variables: {
                    userInput: {
                        args: {},
                        paging: { first: pageModel.limit, skip: pageModel.offset, orderBy: pageModel.ordering + '.' + pageModel.direction },
                    },
                    CompanyInput: {
                        args: {},
                        // hardcoded this params so as to ignore pagination and get all the Companies
                        paging: { first: -1, skip: 0, orderBy: pageModel.ordering + '.' + pageModel.direction },
                    },
                },
            })
            .valueChanges.pipe(
                tap((_) => console.log('Fetched Users')),
                map((result) => {
                    let response = result.data['LoggedInUser']['Users'];
                    return response;
                })
            );
    }

    get(id: string): Observable<UserModel> {
        return this.apollo
            .watchQuery({
                query: USER,
                fetchPolicy: 'no-cache',
                variables: {
                    userInput: {
                        paging: {
                            first: 1,
                            skip: 0,
                            orderBy: 'createdAt.desc',
                        },
                        args: {
                            id: id,
                        },
                    },
                    CompanyInput: {
                        paging: {
                            first: 500,
                            skip: 0,
                            orderBy: 'createdAt.desc',
                        },
                    },
                },
            })
            .valueChanges.pipe(
                tap((_) => console.log('Fetched User')),
                map((result) => {
                    let response = result.data['LoggedInUser']['Users']['data'][0];
                    return response;
                })
            );
    }

    add(payload: any): Observable<UserModel> {
        return this.apollo
            .mutate({
                mutation: USER_CREATE,
                errorPolicy: 'all',
                variables: {
                    input: payload,
                },
            })
            .pipe(
                tap((_) => console.log('User Added')),
                map((res: any) => {
                    const error = res?.errors?.[0];
                    const errorMsg = error?.details?.[0] || error?.message;
                    if (errorMsg) {
                        throw new Error(errorMsg);
                    }
                    return res.data['UserCreate'];
                })
            );
    }

    update(payload: { id: string; active: any; profile: any }) {
        return this.apollo
            .mutate({
                mutation: USER_UPDATE,
                errorPolicy: 'all',
                variables: {
                    input: payload,
                },
            })
            .pipe(
                tap((_) => console.log('User Updated')),
                map((res: any) => {
                    const error = res?.errors?.[0];
                    const errorMsg = error?.details?.[0] || error?.message;
                    if (errorMsg) {
                        throw new Error(errorMsg);
                    }
                    return res.data['UserUpdate'];
                })
            );
    }

    changePassword(payload: any) {
        return this.apollo
            .mutate({
                mutation: USER_PASSWORD_UPDATE,
                variables: {
                    input: payload,
                },
            })
            .pipe(
                tap((_) => console.log('User Password Updated')),
                map((result) => {
                    let response = result.data['PasswordUpdate'];
                    return response;
                })
            );
    }

    delete(id: string) {
        return this.apollo
            .mutate({
                mutation: USER_DELETE,
                variables: {
                    input: {
                        id,
                    },
                },
            })
            .pipe(
                tap((_) => console.log('User Deleted')),
                map((result) => {
                    let response = result.data['UserDisable'];
                    return response;
                })
            );
    }
}
